<template>
  <Card
    :title="serialType[0].toUpperCase() + serialType.substr(1) + ' Group Details'"
    :rows="groupDetailsTable"
  />
</template>
<script>
export default {
  name: 'SerialGroupDetails',
  props: {
    serialType: {
      type: String,
      default: '',
    },
    groupDetailsTable: {
      // generated in SerialDetails when it pulls in all the info
      type: Array,
      default: () => [],
    },
  },
  components: {
    Card: () => import(/* webpackChunkName: "journal_card" */ '@/components/journal/JournalCard.vue'),
  },
}
</script>
